import { gql } from '@apollo/client';

export const GET_MAINTENANCE_MESSAGES = gql`
  query getMaintenanceMessages {
    marblesMaintenanceMessages {
      message
      severity
    }
  }
`;
